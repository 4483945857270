import { create } from 'zustand';


const useAgentStore = create((set) => ({
    agentType: null,
  
    setAgentType: (agentData) => {
      if (agentData.id_agente) {
        set({ agentType: 'Agente' });
      } else if (agentData.id_subconcesionario) {
        set({ agentType: 'SubConcesionario' });
      } else {
        set({ agentType: 'Concesionario' });
      }
    },
  }));
  
  export default useAgentStore;